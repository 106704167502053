// @flow

import { v4 } from 'uuid';
import React, { Component } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FilterService } from '@riseart/filter-service';
import { Query } from 'shared_services/apollo/Query';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { withFilterState } from 'shared_hocs/filters/withFilterState';
import { withIndexedList } from 'shared_hocs/filters/withIndexedList';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';
import { DEFAULT_STORE_STATE as FILTER_DEFAULT_STATE } from 'shared_services/redux/states/filter';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';
import { gtmFilterAdd, gtmFilterRemove } from 'shared_data/providers/google/tagmanager/Actions';

// Const
const HOC_DISPLAY_NAME = 'HOCArtFilterShop';

type Props = Object;

export type FilterContainerProps = {
  domain: string,
  options: Object,
  totalActiveFilters: number,
  listSelectionHandler: Function,
  rangeSelectionHandler: Function,
  isLoading: boolean,
  resetUrl: string,
  fetchQuery: Object,
  additionalQueryParams?: Object,
};

/**
 * HOC
 *
 * @param ArtFilterContainer
 * @returns {State}
 */
function HOC(FilterContainer: React$Component<FilterContainerProps, *>) {
  return class extends Component<Props, *> {
    // Component name in dom tree
    static displayName = HOC_DISPLAY_NAME;

    static defaultProps = {
      filterState: {},
    };

    filterConfig;

    filterService;

    defaultOptions;

    preloaderUniqueId;

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props) {
      super(props);

      this.bindMethods();
      this.filterConfig = getFilterDomainConfig(
        this.props.domain,
        props.urlParams || this.props.match.params,
      );

      if (props.indexedUrls) {
        this.filterConfig.indexedUrls = props.indexedUrls;
      }

      this.filterService = new FilterService(
        this.filterConfig,
        this.props.intl.formatMessage,
        FILTER_DEFAULT_STATE[this.props.domain],
      );

      this.defaultOptions = Object.keys(this.filterService.getConfig().dimensions).reduce(
        (accumulator, dimensionKey) => ({ ...accumulator, [dimensionKey]: [] }),
        {},
      );

      this.preloaderUniqueId = v4();
    }

    /**
     * HandleListSelection
     *
     * @param dimension
     * @param option
     */
    handleListSelection: Function;

    handleListSelection(dimension, option) {
      // Trigger a GTM event
      const gmtEvent = this.gmtFilterEventFactory(dimension, option.value);
      option.selected === false
        ? this.props.actionGtmFilterAdd(gmtEvent)
        : this.props.actionGtmFilterRemove(gmtEvent);

      // Push option URL to router
      if (option.url) {
        const { history } = this.props;
        history.push(option.url);
      }
    }

    /**
     * HandleRangeSelection
     *
     * @param dimension
     * @param options
     */
    handleRangeSelection: Function;

    handleRangeSelection(availableOptions) {
      return (dimension, options) => {
        const values = [];
        let selectedValue: ?string = null;

        if (options instanceof Array) {
          if (options.length > 1) {
            values.push(
              this.filterService.convertDimension(options[0].value, dimension),
              this.filterService.convertDimension(options[1].value, dimension),
            );
          } else if (options.length > 0) {
            values.push(this.filterService.convertDimension(options[0].value, dimension));
          }
        }

        if (values.length > 0) {
          selectedValue = this.filterService.factoryRangeValue(
            dimension,
            values,
            availableOptions.reduce((accumulator, { dimensionName, values }) => {
              if (dimensionName === dimension) {
                return [parseInt(values[0].value, 10), parseInt(values[1].value, 10)];
              }

              return accumulator;
            }, []),
          );

          const url = this.filterService.assemble(
            this.filterService.add(this.props.filterState.filters, {
              [dimension]: selectedValue,
            }),
            {
              layout: this.props.filterState.layout,
              sort: this.props.filterState.sort,
              q: this.props.filterState.q,
              v: this.props.filterState.v,
              l: this.props.filterState.l,
            },
          );

          const { history } = this.props;
          history.push(url);
        }

        this.props.actionGtmFilterAdd(this.gmtFilterEventFactory(dimension, selectedValue));
      };
    }

    /**
     * gmtFilterEventFactory
     *
     * @param {string} dimension
     * @param {string} value
     * @returns {{domain: string, dimension: *, value: *}}
     */
    gmtFilterEventFactory(dimension: string, value: ?string): Object {
      return {
        domain: this.filterConfig.backendDomain,
        dimension,
        value,
      };
    }

    /**
     * BindMethods
     *
     */
    bindMethods() {
      this.handleListSelection = this.handleListSelection.bind(this);
      this.handleRangeSelection = this.handleRangeSelection.bind(this);
    }

    /**
     * Render
     *
     * @returns {XML}
     */
    render() {
      const {
        storeCode,
        filterState,
        additionalFilterQueryParams,
        fetchQuery,
        additionalQueryParams,
      } = this.props;
      const { filters, routed, page, ...restFilterState } = filterState;

      return (
        <Query
          query={fetchQuery || this.filterConfig.selectionQuery}
          variables={{
            inputSelectionFilter: {
              domain: this.filterConfig.backendDomain,
              store: storeCode,
              ...(additionalFilterQueryParams || {}),
              ...Object.keys(filterState.filters).reduce((accumulator, key) => {
                const item = filterState.filters[key];
                return { ...accumulator, [key]: isArray(item) ? item.join(',') : item };
              }, {}),
            },
            ...(additionalQueryParams || null),
          }}
          skip={!storeCode}
          ssr={false}
        >
          {({ loading, data }) => {
            let options = null;
            let totalActiveFilters = 0;
            const queryData =
              data &&
              data[
                get(fetchQuery || this.filterConfig.selectionQuery, 'definitions[0].name.value')
              ];

            if (queryData) {
              options = this.filterService.optionsFactoryDimensions(
                this.props.filterState.filters || {},
                queryData.items,
                {
                  layout: this.props.filterState.layout,
                  sort: this.props.filterState.sort,
                  q: this.props.filterState.q,
                  v: this.props.filterState.v,
                  l: this.props.filterState.l,
                },
              );

              if (options) {
                totalActiveFilters = this.filterService.calcTotalActiveFilters(options);
              }
            }

            return (
              <React.Fragment>
                <DataProviderPreloaderPage uniqueId={this.preloaderUniqueId} attach={loading} />
                <FilterContainer
                  resetUrl={this.filterService.assemble({}, restFilterState)}
                  listSelectionHandler={this.handleListSelection}
                  rangeSelectionHandler={
                    queryData ? this.handleRangeSelection(queryData.items) : null
                  }
                  formatMessage={this.props.intl.formatMessage}
                  isLoading={loading || !storeCode}
                  options={options || this.defaultOptions}
                  totalActiveFilters={totalActiveFilters}
                  domain={this.props.domain}
                />
              </React.Fragment>
            );
          }}
        </Query>
      );
    }
  };
}

/**
 *
 * @param HOCFilterContainer
 * @constructor
 */
export const HOCFilterContainer = (ArtFilterContainer: React$Component<*, *>, options: Object) =>
  injectIntl(
    withTranslatedRouter(
      withFilterState(
        options.domain,
        connect((state): Object => ({
          storeCode: selectStoreCode(state),
          unitLength: selectUnitSystem(state).unitLength,
        }))(
          options.loadIndexedUrlsList
            ? withIndexedList(options.domain, HOC(ArtFilterContainer))
            : HOC(ArtFilterContainer),
        ),
        undefined,
        { actionGtmFilterAdd: gtmFilterAdd, actionGtmFilterRemove: gtmFilterRemove },
      ),
    ),
  );
